import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import './linkstyles.css';
import { DrawerContext } from '../../contexts/DrawerContext';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const ScrollSpyMenu = ({ className, menuItems, drawerClose, ...props }) => {
  const { dispatch } = useContext(DrawerContext);

  const [elementsArray, setElementsArray] = useState(null);

  const [isCaseStudyPage, setisCaseStudyPage] = useState(null);
  // empty array for scrollspy items
  const scrollItems = [];

  // convert menu path to scrollspy items
  menuItems.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  // Add all classs to an array
  const addAllClasses = ['scrollspy__menu'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Close drawer when click on menu item
  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  useEffect(() => {
    if (window.location.pathname.includes('/portfolio/case-study/')) {
      setisCaseStudyPage(true);
    }

    let elementsArray =
      menuItems &&
      menuItems.map((item) => {
        if (item.staticLink === false) {
          if (document.querySelector(item.path)) {
            return '#' + document.querySelector(item.path).getAttribute('id');
          }
        }
        return null;
      });
    setElementsArray([...elementsArray]);
  }, [menuItems]);

  return (
    <Scrollspy
      items={scrollItems}
      className={addAllClasses.join(' ')}
      drawerClose={drawerClose}
      {...props}
    >
      {menuItems.map((menu, index) => {
        let label = menu.label;
        return (
          <li key={`menu-item-${index}`}>
            {menu.staticLink ? (
              <AniLink
                to={menu.path}
                target="_self"
                rel="noreferrer"
                paintDrip
                hex="#56AADA"
              >
                {menu.label}
              </AniLink>
            ) : (
              <>
                {drawerClose ? (
                  label === 'PROJECTS' && isCaseStudyPage ? (
                    <a
                      href={'/'}
                      className="linkProjectCaeStudy"
                      offset={70}
                      //offset={menu.offset}
                      onClick={toggleDrawer}
                    >
                      {menu.label}
                    </a>
                  ) : (
                    (isCaseStudyPage && (
                      <>
                        {(elementsArray &&
                          elementsArray.includes(menu.path) && (
                            <AnchorLink
                              to={`/${menu.path}`}
                              offset={70}
                              //offset={menu.offset}
                              className="hideBlueLine"
                              onClick={toggleDrawer}
                            >
                              {menu.label}
                            </AnchorLink>
                          )) || (
                          <a
                            href={window.location.origin + '/' + menu.path}
                            offset={70}
                            //offset={menu.offset}
                            onClick={toggleDrawer}
                          >
                            {menu.label}
                          </a>
                        )}
                      </>
                    )) || (
                      <AnchorLink
                        to={`${menu.path}`}
                        offset={70}
                        //offset={menu.offset}
                        onClick={toggleDrawer}
                      >
                        {menu.label}
                      </AnchorLink>
                    )
                  )
                ) : label === 'PROJECTS' && isCaseStudyPage ? (
                  <a
                    className="linkProjectCaeStudy"
                    onClick={() => {
                      setisCaseStudyPage(null);
                    }}
                    href={'/'}
                    offset={70}
                    //offset={menu.offset}
                  >
                    <>{menu.label}</>
                  </a>
                ) : (
                  (isCaseStudyPage && (
                    <>
                      {(elementsArray &&
                        elementsArray.includes(menu.path) && (
                          <AnchorLink
                            className="hideBlueLine"
                            to={`/${menu.path}`}
                            offset={70}
                            //offset={menu.offset}
                          >
                            {menu.label}
                          </AnchorLink>
                        )) || (
                        <a
                          href={window.location.origin + '/' + menu.path}
                          offset={70}
                          //offset={menu.offset}
                        >
                          {menu.label}
                        </a>
                      )}
                    </>
                  )) || (
                    <AnchorLink to={`/${menu.path}`} offset={70}>
                      {menu.label}
                    </AnchorLink>
                  )
                )}
              </>
            )}
          </li>
        );
      })}
    </Scrollspy>
  );
};

ScrollSpyMenu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,

  /** Class name that apply to the navigation elements that have been scrolled past [optional]. */
  scrolledPastClassName: PropTypes.string,

  /** HTML tag for Scrollspy component if you want to use other than <ul/> [optional]. */
  componentTag: PropTypes.string,

  /** Style attribute to be passed to the generated <ul/> element [optional]. */
  style: PropTypes.object,

  /** Offset value that adjusts to determine the elements are in the viewport [optional]. */
  offset: PropTypes.number,

  /** Name of the element of scrollable container that can be used with querySelector [optional]. */
  rootEl: PropTypes.string,

  /**
   * Function to be executed when the active item has been updated [optional].
   */
  onUpdate: PropTypes.func,
};

ScrollSpyMenu.defaultProps = {
  componentTag: 'ul',
  currentClassName: 'is-current',
};

export default ScrollSpyMenu;
